import { Box, FormControlLabel, Switch } from "@mui/material";
import {
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  error?: FieldError | undefined;
}

const SwitchField = <T extends FieldValues>({
  label,
  name,
  control,
  error,
  ...props
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { value, onChange, onBlur } = field;
        return (
          <Box
            width={"100%"}
            display="flex"
            flexDirection={"column"}
            gap={1}
            border="1px solid #ccc"
            padding={1}
            borderRadius={"5px"}
          >
            <FormControlLabel
              labelPlacement="start"
              style={{
                justifyContent: "space-between",
              }}
              control={
                <Switch
                  checked={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  color="success"
                />
              }
              label="Is Active"
            />
          </Box>
        );
      }}
      {...props}
    />
  );
};

export default SwitchField;
