import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import queryString from "query-string";
import { getLocalItem } from "../utils/localStorage";

interface IOptions {
  timeout: number;
  language: "en" | "vi";
}

export default abstract class HttpClient {
  protected readonly instance: AxiosInstance;
  constructor(baseURL?: string, options = {} as IOptions) {
    this.instance = axios.create({
      baseURL: baseURL || process.env.REACT_APP_API_URL,
      paramsSerializer: this.getRequestParams,
      timeout: options?.timeout || 20000,
      headers: {
        "Content-type": "application/json",
        authorization: getLocalItem("token"),
        "user-type": "admin", // TODO: based on the type of the web, change this value
      },
    });
    this.requestInterceptor();
    this.responseInterceptor();
  }
  private getRequestParams(params = {}) {
    return queryString.stringify(params);
  }

  private requestInterceptor() {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError,
    );
  }
  private responseInterceptor() {
    const interceptor = this.instance.interceptors.response.use(
      this._handleResponse,
      async (error: AxiosError) => {
        const originalConfig = error?.config as InternalAxiosRequestConfig;
        const token = getLocalItem("access_token");

        const istokenexpired =
          token && [401, 403].includes(error?.response?.status as number);
        if (istokenexpired) {
          // console.log("response", istokenexpired);
        }

        if (
          error?.response?.status === 401 &&
          !window.location.href.includes("/login")
        ) {
          localStorage.removeItem("token");
          window.location.pathname = "/login";
        }

        return Promise.reject(error?.response?.data || error?.message);
      },
    );
  }
  private _handleResponse(response: AxiosResponse) {
    return response;
  }

  private _handleError({ response, message }: AxiosError) {
    return Promise.reject(response?.data || message);
  }
  private async _handleRequest(
    config: InternalAxiosRequestConfig<AxiosRequestConfig>,
  ) {
    const token = getLocalItem("access_token");
    if (token) {
      (config.headers as AxiosRequestHeaders).Authorization = "Bearer " + token;
    }
    return config;
  }
}
