import {
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import InputCus from "../InputCus";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  type?: "text" | "number";
  error?: FieldError | undefined;
}

const TextAreaField = <T extends FieldValues>({
  label,
  name,
  type,
  control,
  error,
  ...props
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: "This is required",
      }}
      render={({ field }) => (
        <>
          <InputCus
            label={label}
            type={type ?? "text"}
            nameInput="description"
            errors=""
            {...field}
          />
          {error && (
            <span
              style={{ color: "red", fontSize: "11px", marginTop: "-10px" }}
            >
              {error?.message}
            </span>
          )}
        </>
      )}
      {...props}
    />
  );
};

export default TextAreaField;
