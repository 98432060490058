import { Autocomplete, Box, TextField } from "@mui/material";
import {
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  error?: FieldError | undefined;
  options: Array<{ name: string; _id: string }>;
}

const SelectField = <T extends FieldValues>({
  label,
  name,
  options,
  control,
  error,
  ...props
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: "This is required",
      }}
      render={({ field }) => {
        const { value, onBlur, onChange } = field;
        return (
          <>
            <Autocomplete
              fullWidth
              options={options}
              style={{
                border: "1px solid #9b9b9b",
                borderRadius: "5px",
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  p: "6px",
                },
                "& .MuiAutocomplete-tag": {
                  bgcolor: "primary.lighter",
                  border: "1px solid",
                  borderColor: "primary.light",
                  "& .MuiSvgIcon-root": {
                    color: "primary.main",
                    "&:hover": {
                      color: "primary.dark",
                    },
                  },
                },
              }}
              onChange={(_, selectedOptions) => {
                onChange(selectedOptions);
              }}
              onBlur={onBlur}
              value={value}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  onChange={(e) => console.log("e", e.target.value)}
                  placeholder={label}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                  }}
                />
              )}
            />

            {error && (
              <span
                style={{ color: "red", fontSize: "11px", marginTop: "-10px" }}
              >
                {error?.message}
              </span>
            )}
          </>
        );
      }}
      {...props}
    />
  );
};

export default SelectField;
