import { HttpClient, URL_API } from "../../network";

export enum ISchemaUpload {
  INFORMATION = "information",
  INFORMATION_CATEGORY = "information-category",
}

class Upload extends HttpClient {
  upload = async (params: { file: File; schema: ISchemaUpload }) => {
    let data = new FormData();
    data.append("file", params.file, params.file.name);

    const { data: result } = await this.instance.post<{
      ok: boolean;
      data: {
        link: string;
      };
    }>(URL_API.UPLOAD + `/${params.schema}`, data, {
      headers: {
        accept: "application/json",
        "Content-Type": `multipart/form-data; `,
      },
    });
    return result;
  };
}
export const UploadService = new Upload();
