import { useEffect, useState } from "react";
import { getLocalItem } from "../utils/localStorage";

const permission = getLocalItem("profile");

export type IRole = Array<{}>;

const usePermission = () => {
  const [role, setRole] = useState<{
    [key: string]: IRole;
  }>({});

  useEffect(() => {
    if (permission) {
      setRole(permission.role);
    } else {
      setRole({});
    }
  }, [permission]);

  return { role };
};

export default usePermission;
